const config = {
  get_feed: 'https://colon.umd.edu/getfeed',
  log_error: 'https://colon.umd.edu/logfrontenderrors',
  database_url: 'https://colon.umd.edu/engagements_save_endless',
  database_attn_url: 'https://colon.umd.edu/attention_save',
  like_tweet: 'https://colon.umd.edu/like_post',
  retweet_tweet: 'https://colon.umd.edu/retweet_post',
  completed_change: 'https://colon.umd.edu/completedstatuschange',
  authorizer: 'https://colon.umd.edu/auth',
  error: 'https://colon.umd.edu/error',
  youGovCompleteRedirect: 'http://g4-us.yougov.com/ereturn/$visa',
  error_codes: {
    no_tweets_main_feed: 0,
    tweet_fetch_error_main_feed: 1,
    no_tweets_attn_check: 2,
    tweet_fetch_error_attn_check: 3
  }
}

export default config;
