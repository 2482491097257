export const SliderData = [
  {
      image: './Instruction_Images/Screenshot1.png'
  },
  {
      image: './Instruction_Images/Screenshot2.png'
  },
  {
      image: './Instruction_Images/Screenshot3.png'
  },
  {
      image: './Instruction_Images/Screenshot4.png'
  },
  {
      image: './Instruction_Images/Screenshot5.png'
  },
  {
      image: './Instruction_Images/Screenshot6.png'
  },
  {
      image: './Instruction_Images/Screenshot7.png'
  },
  {
      image: './Instruction_Images/Screenshot8.png'
  },
  {
      image: './Instruction_Images/Screenshot9.png'
  },
  {
      image: './Instruction_Images/Screenshot10.png'
  },
  {
      image: './Instruction_Images/Screenshot11.png'
  },
  {
      image: './Instruction_Images/Screenshot12.png'
  },
  {
      image: './Instruction_Images/Screenshot13.png'
  },
  {
      image: './Instruction_Images/Screenshot14.png'
  },
]